<template lang="pug">
div
  TableV2(
    :actions="actions"
    :headers="headers"
    :isLoading="isLoading"
    :items="items.results?.data || []"
    :currentPage="items.current"
    :pageCount="items.count"
    :querySearch="getData"
    :total="items.results"
)
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      headers: [
        { value: 'educational_institution', text: this.$t('nameInstitution'), title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') }, sortable: false },
        { value: 'count', text: this.$t('itemsAmount'), align: 'center', total: { global: 'global_sum_count', page: 'page_count_sum', align: 'center' }, sortable: false },
        { value: 'distribution_sum', text: this.$t('distribution'), align: 'center', total: { global: 'global_form2_distribution', page: 'page_form2_distribution', align: 'center' }, sortable: false },
        { value: 'profit_sum', text: this.$t('profit'), align: 'center', total: { global: 'global_form2_profit', page: 'page_form2_profit', align: 'center' }, sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.info',
          to: (item) => ({
            name: 'atc-distribution-qualification-report',
            params: { ...this.$route.params, institutionID: item.educational_institution.id },
            query: { ...this.$route.query, educational_institution: item.educational_institution.id } }),
          color: 'blue',
          target: '_blank',
          name: 'mdi-information-outline'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.advanceTrainingDistribution,
      isLoading: state => state.report.isLoadingReport
    })
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'advanceTrainingDistribution', access: checkAccess('distribution-report') })
  },
  methods: {
    ...mapActions(['getListAdvanceTrainingDistribution']),
    async getData (params) {
      await this.getListAdvanceTrainingDistribution({ params })
    }
  }
}

</script>
